.initials {
  height: 14vh;
  width: auto;
  margin: 1vh 0px 0px 2vw;
  
}

.cover {
  height: 100vh;
}

.cover-shelf {
  margin-top: -5vh;
  margin-right: 3vw;
  margin-left: 3vw;
  border-bottom: 2px solid var(--black);
}
.c-shelf-row {
  height: min-content;
  width: 100%;
  height: 75%;
}

.c-shelf-c1 {
  padding-top: 6vh;
  padding-right: 20vh;
}

.c-shelf-c1 > .paragraph {
  padding-top: 2vh;
  padding-bottom: 4vh;
}
.c-shelf-c1 > .small-bold {
  padding-bottom: 2vh;
}

.c-shelf-c2 {
  border-left: 2px solid var(--black);
  transition: 0.5s ease;
}
.c-shelf-c2:hover {
  background-color: var(--lightergray);
  transition: 0.5s ease;
}

.c-shelf-c3 {
  border-left: 2px solid var(--black);
  margin-top: 115px;
  transition: 0.5s ease;
}
.c-shelf-c3:hover {
  background-color: var(--lightergray);
  transition: 0.5s ease;
}

.c-shelf-c4 {
  border-left: 2px solid var(--black);
  margin-top: 72px;
  transition: 0.5s ease;
}
.c-shelf-c4:hover {
  background-color: var(--lightergray);
  transition: 0.5s ease;
}

.c-shelf-c5 {
  border-left: 2px solid var(--black);
  transition: 0.5s ease;
  padding-bottom: 80px;
}
.c-shelf-c5:hover {
  background-color: var(--lightergray);
  transition: 0.5s ease;
}


.c-title {
  padding: 2.7vw 0px 0px 0px;
  margin: 0 3vw 0 3vw; 
  border-bottom: 2px solid var(--black);
}

.c-name {
  display: flex;
}

.c-age {
  display: flex;
  justify-content: end;
}

.c-age-txt {
  font-family: 'Rader-Thin';
  font-style: normal;
  font-weight: 200;
  font-size: 18vh;
  line-height: 90%;
  float: right;
  justify-content: right;

  color: var(--lightgray);
}

.c-shelf-label {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  writing-mode: vertical-lr;
  padding: 0;
  padding-bottom: 3vh;
  padding-right: 0.5vw;
  

  color: var(--lightgray);
  
}

.c-shelf-label-txt {
  opacity: 0;
  transition: 0.5s ease;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-decoration: none;
  color: var(--lightgray);
}

.c-shelf-explore {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-decoration: none;
  color: var(--lightgray);
}

.c-shelf-label:hover > .c-shelf-label-txt {
  opacity: 100;
  transition: 0.5s ease;
}



.s-header {
  padding: 7vw 0px 0px 0px;
  margin: 0 3vw 0 3vw; 
  border-bottom: 2px solid var(--black);
}

.s-header-text {
  display: flex;
  align-items: flex-end;
}

.s-decor-container {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}

.s-decore-box {
  width: 100%;
}

.s-decor {
  display: flex;
  justify-content: flex-end;
  background-color: var(--black);
  color: var(--black);
  height: 2px;
  width: 100%;
  opacity: 100%;
  padding-bottom: 0;
  margin-bottom: 5vh;

  margin-left: auto;
  margin-right: 0;
}
.d-1 {
  width: 60%;
}
.d-2 {
  width: 80%;
}
.d-3 {
  width: 100%;
}